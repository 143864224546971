<template>
    <b-modal
      id="mat-med-extra-fields-modal"
      size="xl"
      hide-header
      hide-footer
      centered
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p class="title">Editar campos adicionais - {{ getItemName() }}</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <div class="module-title">Dados Adicionais</div>
            <b-row>
                <b-col cols="8">
                    <b-form-group>
                        <label>Tabela</label>
                        <multiselect
                            id="table_number"
                            label="label"
                            track-by="value"
                            v-model="form.table_number"
                            :options="tableOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="Selecionar"
                            class="with-border"
                            :disabled="!editable"
                        >
                            <template slot="caret">
                                <div class="chevron">
                                    <ChevronDown />
                                </div>
                            </template>
                            <template slot="noOptions"> Nenhuma opção </template>
                            <template slot="noResult"> Nenhum resultado </template>
                        </multiselect>
                        <b-form-invalid-feedback :state="isValidProp('table_number')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group>
                        <label>Código de despesa (CD)</label>
                        <multiselect
                            id="cd"
                            v-model="form.cd"
                            :options="cdOptions"
                            :showLabels="false"
                            :searchable="false"
                            :allowEmpty="false"
                            placeholder="Selecionar"
                            class="with-border"
                            :disabled="!editable"
                        >
                            <template slot="caret">
                                <div class="chevron">
                                    <ChevronDown />
                                </div>
                            </template>
                            <template slot="noOptions"> Nenhuma opção </template>
                            <template slot="noResult"> Nenhum resultado </template>
                        </multiselect>
                        <b-form-invalid-feedback :state="isValidProp('cd')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>Unidade de medida</label>
                        <UnitMeasurementSelect 
                            v-model="form.unit_measurement" 
                            :disabled="!editable"
                        />
                        <b-form-invalid-feedback :state="isValidProp('unit_measurement')">Campo obrigatório</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            
            <b-row v-if="editable">
                <b-col>
                    <b-button 
                        variant="primary" 
                        class="float-right" 
                        :loading="loading"
                        :disabled="loading"
                        @click="save"
                    >
                        Salvar
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
import api from '@itemOtherExpenseProps/api'
import * as ITEM from '@items/utils/constants'
import { TISS_TABLE_OPTIONS } from '@itemOtherExpenseProps/utils/constants'
import * as CONTANTS from '@itemOtherExpenseProps/utils/constants'
import { isValidExtraForm } from '@itemOtherExpenseProps/utils/validations'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        UnitMeasurementSelect: () => import('@itemOtherExpenseProps/components/UnitMeasurementSelect'),
    },
    props: {
        editable: {
            type: Boolean,
            default: true
        },
        item: {
            type: Object,
            default: null
        },
        type: String,
        updateList: Function,
    },
    data() {
        return {
            ITEM,
            form: {
                id: null,
                table_number: null,
                cd: null,
                unit_measurement: null,
            },
            loading: false,
            validated: false,
            clinic: getCurrentClinic(),
            tableOptions: TISS_TABLE_OPTIONS,
            cdOptions: CONTANTS.EXPENSE_CD_TYPES
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('mat-med-extra-fields-modal')
        },
        onHidden() {
            this.form = {}
            this.$emit('hidden')
        },
        getItemName(){
            return this.type === 'S' ? this.item?.descricao : this.item?.item + ' - ' + this.item?.apres;
        },
        isValidProp(prop) {
            if (!this.validated) return null
            if (prop === 'quantity' && this.form.quantity < 0) return false
            return !!this.form[prop]
        },
        async save() {
            this.validated = true 
            if (!isValidExtraForm(this.form)) {
                console.log('errro');
                return
            }
            this.loading = true
            const payload = {
                cd: this.form.cd,
                table_number: this.form.table_number.value,
                unit_measurement: this.form.unit_measurement
            };
            try {
                const { data } = this.type === 'S' ? await api.updateExtraFieldsSimpro(this.item.id, payload) : await api.updateExtraFieldsBrasindice(this.item.id, payload)
                this.$toast.success(`Item salvo com sucesso!`)
                this.$emit('saved', data)
                this.$emit('updateList')
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validated = false
                this.loading = false
            }
        },
    },
    watch: {
        item(data) {
            if (!data) {
                this.form = {}
                return
            }
            this.form = {
                ...data,
                table_number: this.tableOptions.find(({ value }) => value === data?.table_number) 
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
    }
    .module-title {
        font-size: 16px;
        font-weight: 700;
        color: var(--blue-500);
        margin-bottom: 1rem;
    }
    .radio-group {
        margin-top: 7px;
    }
</style>
<style lang="scss">
#mat-med-extra-fields-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 24px;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
}
</style>